import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    connect() {
        const _this = this;
        document.addEventListener("turbo:before-fetch-response", (event) => {
            _this.redirect(event)
        })
    }

    redirect(event) {
        const {url, redirected} = event.detail.fetchResponse.response;

        if ((redirected) && (url.match("redirect_html=true")))
        {
            event.preventDefault();
            var location = new URL(url);
            location.searchParams.delete('redirect_html');
            window.location.href = location
        }
    }
}