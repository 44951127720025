import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

    static targets = ['stepOne', 'stepTwo']

    stepInputIds = [
        [
            'domain',
            'cuvee',
            'color',
            'wine_type',
            'autoinput',
            'appellation',
            'region',
            'country',
            'identification',
        ],
        [
            'millesime',
            'sample_type',
            'writed_at',
            'comment',
            'information',
            'rating',
            'favorite',
        ]

    ];

    disconnect() {
        this.removeValues(this.stepInputIds.flat());
    }

    stepOneTargetConnected() {
        this.setInputsValues(this.stepInputIds[0]);
    }

    stepOneTargetDisconnected() {
        this.removeValues(this.stepInputIds[0]);
    }

    stepTwoTargetConnected() {
        this.setInputsValues(this.stepInputIds[1]);
    }

    storeValues(event) {
        this.getInputValues(this.stepInputIds.flat());
    }

    clearValues(event) {
        this.removeValues(this.stepInputIds.flat());
    }

    validateStepOne(event) {
        event.preventDefault();

        if (!event.target.form.checkValidity()) {
            event.target.form.reportValidity();
            return;
        }

        const cuvee = this.element.querySelector('#cuvee');
        const domain = this.element.querySelector('#domain');
        const headers = new Headers({
            "accept": "application/json",
        })

        fetch(`/comments/valid_identification?cuvee=${cuvee.value}&domain=${domain.value}`, {
            method: 'GET',
            headers: headers
        }).then((response) => {
            return response.json()
        }).then((data) => {
            if (data.values.domain !== null || data.values.cuvee !== null) {
                this.syntaxAlert(data.values);
            } else {
                event.target.form.requestSubmit();
            }
        });
    }

    getInputValues(ids) {
        ids.forEach((id) => {
            const input = this.element.querySelector(`#${id}`);
            if (input !== null) {
                if (input.type === 'checkbox') {
                    window.sessionStorage.setItem(id, input.checked ? '1' : '0' ?? '0');
                } else {
                    window.sessionStorage.setItem(id, input.value ?? ''); // we use sessionStorage to keep uniq values for each tabs
                }
            }
        });
    }

    setInputsValues(ids) {
        ids.forEach((id) => {
            const value = window.sessionStorage.getItem(id);
            const input = this.element.querySelector(`#${id}`);
            if (value !== null && input !== null) {
                if (input.type === 'checkbox') {
                    input.checked = value === '1';
                } else {
                    input.value = value;
                }
            }
        });
    }

    removeValues(ids) {
        ids.forEach((id) => {
            window.sessionStorage.removeItem(id);
        });
    }

    syntaxAlert(newValues) {
        Swal.fire({
          title: 'Attention !',
          text: "Des domaines ou cuvées existent déjà avec ce nom mais avec une syntaxe différente !",
          icon: 'warning',
          confirmButtonColor: 'var(--society-color, #3085d6)',
          confirmButtonText: 'Remplacer',
        }).then((result) => {
          if (result.isConfirmed) {
              if (newValues.cuvee !== null) {
                  this.element.querySelector('#cuvee').value = newValues.cuvee;
              }
              if (newValues.domain !== null) {
                  this.element.querySelector('#domain').value = newValues.domain;
              }
          }
        })
    }
}
