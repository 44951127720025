import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static values = {
        waitTime: Number,
        inputId: { type: String, default: 'query' }
    }

    connect() {
        var timer = 0;
        this.waitTime = this.waitTimeValue;


    }

    request(event) {
        const input = $(`[id=${this.inputIdValue}]:visible`);
        const form  = $(input).closest('form').get(0);
        clearTimeout(this.timer)

        this.timer = setTimeout(() => {
            form.requestSubmit()
        }, this.waitTime)
    }
}