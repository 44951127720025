import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static values = {
        submitId: String,
        checkAssociations: Boolean,
        enableSubmitButton: Boolean
    }

    connect() {
        this.recordIds = new Set();

        // Reset set after search or filtered
        var _this = this;
        $(window).on('turbo:before-fetch-request', function() {
            _this.recordIds.clear();
        });
    }

    select_unselect_card(event) {
        var card     = $(event.currentTarget);
        var checkbox = card.find('#checkbox');

        if (checkbox.is(':checked')) {
            card.removeClass('bg-dark-grey');
            checkbox.prop('checked', false);
            this.recordIds.delete(card.data('recordId'))

            if ((this.enableSubmitButtonValue) && (this.recordIds.size == 0)){
                let submitButton = $(`#${this.submitIdValue}`)

                // Enable button
                submitButton.prop('disabled', true);
                submitButton.tooltip('enable')
            }
        }
        else {
            this.select_if_possible(card);
        }
    }

    select_if_possible(card, checkbox){
        if (this.recordIds.size > 0){
            if (this.checkAssociationsValue){
                this.check_if_associations_match(card);
            }
            else {
                // FIXME: Recheck this part
                this.select(card)
            }
        }
        else {
            this.select(card)
            if (this.checkAssociationsValue){
                let associated_ids = card.data('associatedIds');
                this.associated_ids = associated_ids.length > 0 ? new Set(associated_ids)
                                                                : new Set(['no_associations'])
            }
            if (this.enableSubmitButtonValue){
                let submitButton = $(`#${this.submitIdValue}`)

                // Enable button
                submitButton.prop('disabled', false);
                submitButton.tooltip('disable')
            }
        }
    }

    select(card) {
        var checkbox = card.find('#checkbox');

        // Add css classes and check box
        card.addClass('bg-dark-grey');
        checkbox.prop('checked', true);

        // Add record Id to already selected ids
        this.recordIds.add(card.data('recordId'));
    }

    check_if_associations_match(card){
        // Check if associated ids of clicked card corresponds with those of already selected cards
        let associated_ids = card.data('associatedIds')
        const associations_match = associated_ids.length > 0 ? (this.associated_ids.size == associated_ids.length) && (associated_ids.every(el => this.associated_ids.has(el)))
                                                             : (this.associated_ids.size == 1) && (this.associated_ids.has('no_associations'));

        if (associations_match){
            this.select(card)
        }
        else {
            card.attr('data-bs-original-title', "Vous ne pouvez pas sélectionner des cartes avec des conclusions différentes")
                .tooltip('enable')
                .tooltip('show')

            card.on('mouseleave', function() {
                $(this).tooltip('disable');
            });
        }
    }
}