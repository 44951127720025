import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = ['conceptInput', 'conceptSelection', 'addFieldButton']

    fillConceptSelection(event) {
        let selectedConcept = $(event.currentTarget).find(':selected').text();

        $(this.conceptInputTarget).val(selectedConcept[0].toUpperCase() + selectedConcept.slice(1));
    }

    checkNumberOfChunks(event) {
        let is_deletion = $(event.currentTarget).hasClass('fa-trash');
        let n_chunks = (is_deletion ? $('.chunk:visible').length - 1 : $('.chunk:visible').length + 1);

        if (n_chunks >= 3) {
            $(this.addFieldButtonTarget).toggleClass('d-none d-block')
        }
        else if ( !$(this.addFieldButtonTarget).hasClass('d-block') ) {
            $(this.addFieldButtonTarget).toggleClass('d-none d-block')
        }
    }

    setPos(event) {
        let lastPos = $('.chunk:visible').last().find('.chunkPos');
        var observer;

        if (lastPos.length > 0) {
            observer = this.mutationObserver(parseInt(lastPos.val()));

        }
        else {
            observer = this.mutationObserver()
        }

        observer.observe($('#chunks').get(0), { childList: true });
    }

    updatePos(lastPos) {
        $('.chunk:visible').last().find('.chunkPos').val( lastPos + 1 )
    }

    mutationObserver( lastPos = -1 ) {
        var _this = this;
        let observer = new MutationObserver(function(mutations) {
            if ($('.chunk:visible').length) {
                _this.updatePos(lastPos);
                observer.disconnect()
            }
        })

        return observer
    }
}