import { Controller } from "@hotwired/stimulus"
import { destroy } from '@rails/request.js'

export default class extends Controller {
    static values = {
        redirectUrl: String
    }

    confirm_delete(event) {
        var delete_url = event.currentTarget.href;
        event.preventDefault()
        Swal.fire({
            template: '#my-template',
            toast: true,
            width: 'auto'
        }).then(async (result) => {
            let response;
            if (result.isConfirmed){
                response = await destroy(delete_url, {
                    responseKind: 'json'
                })
                if (response.ok){
                    let redirect_path = await response.json
                    window.location.href = redirect_path.redirect_path
                }
            }
        });
    }
}