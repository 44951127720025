/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
//const images = require.context('../images', true)
//const imagePath = (name) => images(name, true)

require("jquery")
require("@nathanvda/cocoon")
require("@hotwired/turbo-rails")
// Stimulus controllers
require('controllers')
require('controllers/select2/adapters.js')

// Require all from plots
require('plots/sunburst')

// External plotly
import Plotly from 'plotly';
global.Plotly = Plotly;

// select2
import 'select2/dist/js/select2.full.js'
import 'select2/dist/css/select2.css';
import 'select2/dist/js/i18n/fr.js';
import 'popper.js'
import 'bootstrap'


// Toastr notification
global.toastr = require("toastr")


// Append by default in cocoon
$(window).on('turbo:render load', function() {
    $('[data-toggle="tooltip"]').tooltip();
    $('[data-toggle="popover"]').popover();
    $('form').on('cocoon:after-insert', function(e, added_thing) {
        let select = added_thing.find('.select2')
        if (select) {
            let id = select.attr('id');
            select.attr('id', `select_${(Math.random() + 1).toString(36).substring(7)}`)
        }
    });

    $('form').on('cocoon:before-remove', function(event) {
        if ($(event.currentTarget).data('mustHaveOne')){
            if ($('.remove_association:visible').length == 1){
                event.preventDefault()
                toastr.warning('Vous devez sélectionner au minimum une conclusion')
            }
        }
    });

    // Select 2 focus
    $(document).on('select2:open', (event) => {
        if (event.target.multiple) { return; }

        let search_fields = $('.select2-search__field')
        search_fields[search_fields.length - 1].focus()
    });
});


// images
require.context('../images', true)

// confirm with sweet alert
require('plugins/sweet_alert.js')


