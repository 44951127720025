import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = ['dropdown', 'icon', 'text']

    connect() {
        let current_url = window.location.href;
        let dropdown_id = $(this.dropdownTarget).attr('id');

        if (current_url.includes(dropdown_id)) {
            $(this.dropdownTarget).toggleClass('d-none d-block')
            $(this.dropdownTarget).closest(".fas").toggleClass('fa-chevron-down fa-chevron-up color-pink')
        }
    }

    toggle(event) {
        if (this.hasIconTarget) {
            $(this.iconTarget).toggleClass('fa-chevron-down fa-chevron-up')
        }
        if (this.hasTextTarget) {
            let btn_text = $(this.textTarget).text()
            btn_text = btn_text.includes('plus') ? btn_text.replace('plus', 'moins')
                                                 : btn_text.replace('moins', 'plus')

            $(this.textTarget).text(btn_text)
        }
        $(this.dropdownTarget).toggleClass('d-none d-block')

        // FIXME: Refactoring this
        
    }
}