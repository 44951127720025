import { Controller } from "@hotwired/stimulus"
import { get, post } from '@rails/request.js'

export default class extends Controller {
    static targets = ["pagination"]
    static values  = {
        lastHeader: String
    }

    connect() {
        this.lastScrollTop = 0;
        this.dom_id = this.element.id;
        let _this = this;

        $(document).on('turbo:before-stream-render', function(event){
            let target = $(event.target)
            if ((target.attr('target') == _this.dom_id) && (target.attr('action') == 'update')) {
                _this.lastScrollTop = 0;
            }
        })

    }

    scroll() {
        let url = '#'

        if (this.hasPaginationTarget)
        {
            url  = this.paginationTarget.querySelector("a[rel='next']");
            if (url)
            {
                url = url.href;

                // look if user is at bottom of the page
                let st = $(window).scrollTop()

                if ((st  > $(window).height() / 2.5) && (this.lastScrollTop < st)){
                    $(this.paginationTarget).remove()
                    this.loadMore(url);
                    this.lastScrollTop = $(window).scrollTop();
                }

            }
        }
    }

    loadMore(url){
        let hidden_fields    = $('#hidden_fields');
        let lastHeader       = $('#lastHeader')
        let lastNestedHeader = $('#lastNestedHeader')
        let params = {};

        if (hidden_fields.length > 0) {
            hidden_fields.children('input').each(function() {
                let input_dom = $(this);
                params[`${input_dom.attr('id')}`] = input_dom.val();
            })
        }

        if (lastHeader.length > 0) {
            let lastHeaderName = lastHeader.data('last-header');
            params['lastHeader'] = lastHeaderName;
            lastHeader.remove();
        }

        if (lastNestedHeader.length > 0) {
            let lastNestedHeaderName = lastNestedHeader.data('last-header');
            params['lastNestedHeader'] = lastNestedHeaderName;
            lastNestedHeader.remove();
        }

        if (url.includes('search')) {
            // Hide all queries
            $("[id^='query']:visible").each(function(_, element) {
                params[element.id] = $(element).val();
            })

            post(url, {
                responseKind: 'turbo-stream',
                body: params
            });
        }
        else {
            get(url, {
                responseKind: 'turbo-stream',
                query: params
            });
        }
    }
}
