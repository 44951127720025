import { Controller } from "@hotwired/stimulus"
import { EchartsApi, Radar } from '../helpers/echarts_helper'

export default class extends Controller {
    static values = {
        kind: { type: String, default: 'sunburst' }
    }
    static targets = ['plot']

    connect() {
        this.first_load = true;

        try {
            this.check_kind();
            if ( $('#pills-analyse.active').length || !($('#pills-analyse').length)) {
                this.plot_chart();
            }
            else {
                let _this = this;
                $(window).on('shown.bs.tab', function() {
                    if ($('#pills-analyse.active').length && _this.first_load) {
                        _this.plot_chart();
                        _this.first_load = false;
                    }
                })
            }
        }
        catch(e) {
            console.log(e.message)
            $(this.element).remove()
        }
    }

    check_kind() {
        if ($.inArray(this.kindValue, ['radar', 'sunburst']) != -1) {
            return
        }
        else {
            throw new Error("Le type de graphique n'est pas dans la liste");
        }
    }

    resize_sunburst(event) {
        relayout(this.plotTarget.id);
    }

    plot_chart() {
        // Get data
        let data = $(this.plotTarget).get(0)
                                     .dataset

        if (this.kindValue == 'radar') {
            this.radar(data)
        }
        else {
            let plot_data = JSON.parse(data.plot);
            eval(`${this.kindValue}(this.plotTarget.id,
                                    plot_data,
                                    data)`);
        }
        //$('.modebar').toggle('d-none');
    }

    radar(data) {
        let small_device = $(window).width() < 1200;
        let plot_data = JSON.parse(data.plot)

        let chart = new EchartsApi('radar', null, { renderer: 'canvas' })
        let radar = new Radar({color: data.color});

        chart.set_title({
            text: data.domain,
            textStyle: {
                fontStyle: 'normal',
                fontWeight: 'normal',
                fontSize: small_device ? 12 : 14,
                fontFamily: 'sans-serif',
                color: '#707070'
            },
            itemGap: 5,
            subtext: data.cuvee,
            subtextStyle: {
                fontStyle: 'normal',
                fontWeight: 'bolder',
                fontSize: small_device ? 13 : 15,
                fontFamily: 'sans-serif',
                color: '#707070'

            },
            x: 'center',
            top: 25,
        })


        $.each(plot_data.labels.slice(0, plot_data.labels.length - 1), function(i, label) {
            let name = $(window).width() < 500 ? label.split(' / ')[0] : label;
            radar.add_incator({label: name, min: 1.8, max: 4.2});
        })

        let values = plot_data.values.slice(0, plot_data.values.length - 1)
        radar.add_serie({
            name: 'Bouche',
            values: values,
            color: data.color,
            symbol: 'none',
            lineColor: data.color,
            lineWidth: 2.25
        })
        radar.add_serie({
            name: 'Moyenne',
            values: Array(plot_data.values.length - 1).fill(3),
            color: 'transparent',
            symbol: 'none',
            lineColor: '#a22716',
            lineWidth: 1,
            lineType: [6, 10]
        })


        chart.set_plot(radar.data());
        chart.plot()

        //my_chart.setOption(data)
        this.chart = chart
        window.addEventListener('resize', this.chart.chartInstance.resize);
    }

    downloadSunburst() {
        $('a[data-title="Download plot as a png"]')[0].click()
    }
}