import Autocomplete from 'stimulus-autocomplete';

export default class extends Autocomplete {
    connect() {
        super.connect();

        this.inputTarget.addEventListener("click", this.onInputClick);
        this.inputTarget.addEventListener("focusout", this.onInputFocusOut);
    }

    onInputClick = () => {
        if (this.hasInputTarget) this.inputTarget.value = "";
    }

    onInputFocusOut = () => {
        if (this.hasInputTarget) this.inputTarget.value = this.hiddenTarget.value;
    }
}
