import Swal from 'sweetalert2'
import 'sweetalert2/src/sweetalert2.scss'

window.Swal = Swal;

Turbo.setConfirmMethod((message, _element) => {
    return Swal.fire({
        title: message,
        showCancelButton: true,
        confirmButtonText: 'Oui',
        confirmButtonColor: 'var(--society-color, #3085d6)',
        cancelButtonText: 'Non',
        allowOutsideClick: false,
        allowEscapeKey: false,
    }).then((result) => {
        return result.isConfirmed;
    });
});
