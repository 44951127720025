import * as echarts from 'echarts';

export class EchartsApi {
  constructor(dom_id, theme , opts) {
    this.chartInstance = echarts.init(document.getElementById(dom_id), theme, opts)
    this.data = {
      graphic: {
        elements: [
          {
            type: 'text',
            style: {
              text: '© Winespace',
              textAlign: 'center'
            },
            right: 10,
            bottom: 60
          }
        ]
      }
    }
  }

  set_plot(plot) {
    this.data = { ...this.data, ...plot };
  }

  set_title(opts) {
    this.data['title'] = opts
  }

  add_image(image_opts) {
    this.data.graphic.elements.push(image_opts)
  }

  plot() {
    this.chartInstance.setOption(this.data)
  }

  download(opts) {
    this.chartInstance.resize({height: opts.height, width: opts.width})

    // Copy options
    let options = this.chartInstance.getOption()
    let opts_copy = JSON.parse(JSON.stringify(options));

    // Set bottom margin for graphic elements
    $.each(opts_copy.graphic[0].elements, function(i, element) {
      element.bottom = opts.bottom_margin
      if ('image' in element.style) {
        element.style.image = options.graphic[0].elements[i].style.image
        element.style.height = 142
      }
    });
    this.chartInstance.setOption(opts_copy)

    $('#download').attr('download', opts.filename)
    $('#download').attr('href',
                        this.chartInstance.getDataURL({type: opts.type, backgroundColor: opts.bgColor }))
    $('#download')[0].click();

    this.chartInstance.setOption(options)
    this.chartInstance.resize({height: 'auto', width: 'auto'})
  }
};


export class Radar {
  constructor(opts = {}) {
    let small_device = $(window).width() < 1200;

    this.radar = {
      indicator: [],
      radius: $(window).width() < 800 ? '60%' : '75%',
      center: ['50%', '50%'],
      startAngle: 75,
      axisName: {
        color: '#707070',
        fontWeight: 'bolder',
        fontFamily: 'sans-serif',
        fontSize: small_device ? 11 : 14,
        show: true,
      },
      splitNumber: 6,
      splitLine: {
        lineStyle: {
          color: "#acacac",
          width: 1.25
         }
      },
      splitArea: {
        areaStyle: {
          color: '#f1f1f15e',
          shadowColor: 'rgba(0, 0, 0, 0.25)',
          shadowBlur: 10,
          opacity: 0.5
        }
      },
      axisLine: {
        lineStyle: {
          width: 0.75
        }
      }
    }

    if (small_device) {
      this.radar.axisName.padding = [50, -10]
    }

    this.series = []
  }

  add_incator(opts) {
    this.radar['indicator'].push({
      name: opts.label,
      min: opts.min,
      max: opts.max
    });
  }

  add_serie(opts) {
    this.series.push({
      name: opts.name,
      type: 'radar',
      areaStyle: {
        color: opts.color,
        opacity: 0.4
      },
      data: [{
        value: opts.values,
        lineStyle: {
          color: opts.lineColor,
          width: opts.lineWidth,
          opacity: 1,
          type: opts.lineType
        },
        symbol: opts.symbol,
        itemStyle: {
          color: opts.lineColor
        }
      }],
    })
  }

  data() {
    return { radar: this.radar,
             series: this.series };
  }
}