import { Controller } from "@hotwired/stimulus"
import { hashUrl } from 'plugins/url_hasher.js'

export default class extends Controller {
    static targets = ['links', 'form']
    static values = {
        restore: { type: Boolean, default: false },
        restoreUrl: { type: String, default: '' }
    }

    connect() {
        // set session storage for page
        if (!this.restoreValue) {
            this.urlId = hashUrl(window.location.pathname)
            this.clearSessionIfNecessary();

            this.setSessionStorage();
        }
        else {
            // Change all links on the page to take parameters into account
            this.urlId = hashUrl(this.restoreUrlValue)
            this.searchParams = this.defineSearchParams();
            if (this.hasFormTarget) {
                this.changeActionIfCache();
            }

            var _this = this;
            $.each(this.linksTargets, function (_, link) {
                _this.changeHrefIfCache($(link));
            });
        }
    }

    clearSessionIfNecessary() {
        let last_cache_for = sessionStorage.getItem('last_cache_for')
        if ( (last_cache_for != this.urlId) || (!window.location.search)) {
            sessionStorage.removeItem(last_cache_for)
        }
    }

    setSessionStorage() {
        sessionStorage.setItem('last_cache_for', this.urlId)
        if (!sessionStorage.hasOwnProperty(this.urlId)) {
            let cache = {
                queries: {},
                filters: {}
            }
            this.setCache(cache);
        }
    }

    setKey(event) {
        var cache = this.getCache();
        var value = $(event.currentTarget).val();

        // update key cache
        cache[event.params.key][event.params.domId] = value;

        // set sessionStorage cache
        this.setCache(cache);
    }

    getCache() {
        return JSON.parse(sessionStorage.getItem(this.urlId));
    }

    setCache(cache) {
        sessionStorage.setItem(this.urlId, JSON.stringify(cache))
    }

    changeActionIfCache() {
        let url = $(this.formTarget).attr('action');

        if (this.searchParams.toString()) $(this.formTarget).attr('action',  `${url}?${this.searchParams.toString()}`);
    }

    defineSearchParams() {
        this.searchParams = new URLSearchParams();


        this.setParams('queries')
        this.setParams('filters')

        return this.searchParams;
    }

    changeHrefIfCache(link) {
        let href = link.attr('href')
        if (this.searchParams.toString()) link.attr('href', `${href}?${this.searchParams.toString()}`)
    }

    setParams(cacheKey) {
        let cache = this.getCache();
        let _this = this;

        $.each(cache[cacheKey], function (key, value) {
            if (value) _this.searchParams.set(key, value);
        });
    }

}