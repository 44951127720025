import { Controller } from "@hotwired/stimulus";
import Pickr from "@simonwep/pickr";

export default class extends Controller {
    static targets = ["picker", "input", "color"]

    initialize() {
      this.initPicker();
      this.fetch_color();
    }

    initPicker() {
      this.picker = Pickr.create({
        el: this.pickerTarget,
        theme: 'classic',
        default: this.inputTarget.value,
        appClass: 'col-2 col-lg-1',

        components: {
          preview: true,
          opacity: true,
          hue: true,

          interaction: {
            hex: true,
            rgba: true,
            hsla: false,
            hsva: false,
            cmyk: false,
            input: true,
            clear: false,
            save: true,
          },
        },
      });

      this.picker.on('save', (color, _instance) => {
        this.inputTarget.value = color.toHEXA().toString();

        this.picker.hide();
      });

      this.picker.on('show', (color, _instance) => {
          $('.pcr-last-color').css('--pcr-color', this.parent_color);
      });
    }

    fetch_color() {
      let id = this.colorTarget.value
      if (id) {
        fetch(`/flavour/concepts/${id}/color`, { headers: { accept: "application/json" } })
          .then(response => response.json())
          .then( (data) => {
            this.parent_color = data.color || '#fff';
          });
      }
      else
      {
        this.parent_color = '#fff'
      }
    }
  }