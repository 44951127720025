
function customMatch(params, data) {
    return match(params, data)
}

function customMatchWithoutParentheses(params, data) {
    return match(params, data, true)
}

function match(params, data, withoutParentheses = false) {
    // If there are no search terms, return all of the data
    if ($.trim(params.term) === '') {
        return data;
    }

    let modifiedData = []

    let value = data.text
    if (value) {
        let match = true
        value = value.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "")
        if (withoutParentheses) {
            value = value.replace(/\(.*\)/g, '')
        }
        $.each(params.term.split(' '), (_key, termValue) => {
            match = match && (value.includes(termValue.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "")))
        })
        if (match) {
            modifiedData.push(data)
        }
    }

    if (modifiedData.length) {
        return $.extend({}, data, true);
    }

    // Return `null` if the term should not be displayed
    return null;
}

export { customMatch, customMatchWithoutParentheses }