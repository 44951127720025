import { Controller } from "@hotwired/stimulus"
import { get, post } from '@rails/request.js'

export default class extends Controller {
    static targets = ['input', 'country', 'region']

    fillCountryRegion(event) {
        let option = $(event.currentTarget).text().trim();
        let type = $(event.currentTarget).data('type');
        let headers = new Headers({
            "accept": "application/json",
        })

        fetch(`/wia/appellations/${encodeURIComponent(option)}?type=${type}`, {
            method: 'GET',
            headers: headers
        }).then( (response) => {
            return response.json()
        })
          .then( (data) => {
            $(this.countryTarget).val(data["country"]);
            $(this.regionTarget).val(data["region"]);
          });
    }

    addAppellation(event) {
        let appellation = $(this.inputTarget).val()
        get('/comments/add_appellation', {
            responseKind: 'turbo-stream',
            query: { q: appellation }
        });
    }

    autocompleteRegion(event) {
        let country = event.type == 'keyup' ? $(event.currentTarget).val() : $(event.currentTarget).text();
        $('#autocompleteRegion').attr('data-autocomplete-url-value', `/autocomplete/regions?country=${country}`);
    }

    autocompleteCuvees(event) {
        let domain = event.type == 'keyup' ? $(event.currentTarget).val() : $(event.currentTarget).text();
        $('#autocompleteCuvee').attr('data-autocomplete-url-value', `/autocomplete/cuvees?domain=${domain}`);
    }

    goToCommentForm(event) {
        let kind = $(event.currentTarget).data('kind');
        let cuvee_id = $(event.currentTarget).data('id');
        let comment_id = $('#comment_id').val();

        post('/comments/display_comment_form', {
            responseKind: 'turbo-stream',
            body: { cuvee_id: cuvee_id,
                    comment_id: comment_id,
                    kind: kind,
                    locked: true,
                    form_kind: 'autocomplete',
            }
        });
    }
}
