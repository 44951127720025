import {Controller} from '@hotwired/stimulus';
import {post} from '@rails/request.js';

export default class extends Controller {
    static targets = ['conditionRow', 'selectMethod', 'selectConstraint', 'selectValues'];

    connect() {
        // prevent turbo cache
        Turbo.cache.exemptPageFromCache();

        $(this.conditionRowTarget).ready(() => {
            // dispatch change event triggered by select2
            $(this.selectMethodTarget).on('select2:select', function() {
                const event = new Event('change', { bubbles: true });
                this.dispatchEvent(event);
            });

            // build select2 on connection
            $(this.selectMethodTarget).select2({ language: 'fr', placeholder: '' });
            $(this.selectConstraintTarget).select2({ language: 'fr' });
            this.updateSelectValuesByMethod();
        });
    }

    updateValues(_event) {
        this.updateSelectValuesByMethod();

        post('/queries/change_values', {
            body: {
                method: $(this.selectMethodTarget).val(),
                target: $(this.selectValuesTarget).attr('id'),
            },
            responseKind: 'turbo-stream'
        } );
    }

    removeRow(_event){
        const rowsCount = $('.condition-row').length - 1;

        if (rowsCount === 1) {
            toastr.error('Impossible de supprimer une ligne unique');
            return;
        }

        $(this.conditionRowTarget).remove();
    }

    createAppellationSelect(obj) {
        const _this = this;
        obj.select2({
            ajax: {
                url: '/items/origin',
                data: function (params) {
                    // Query parameters will be ?q=[term]&page=[page]
                    return {
                        q: params.term,
                        scope: $(_this.selectMethodTarget).val().replace('by_', ''),
                        page: params.page || 1
                    };
                },
                processResults: function (data) {
                    return data.items;
                },
                dataType: 'json',
                delay: 250,
            },
            minimumInputLength: 3,
            placeholder: '',
            language: 'fr',
        });
    }

    updateSelectValuesByMethod() {
        const method = $(this.selectMethodTarget).val();
        switch (method) {
            case 'by_appellation':
            case 'by_region':
            case 'by_country':
                this.createAppellationSelect($(this.selectValuesTarget));
                break;
            default:
                $(this.selectValuesTarget).select2({ placeholder: '', language: 'fr' });
                break;

        }
    }
}
