$.fn.select2.amd.define('CustomDataAdapter', ["select2/data/array", "select2/utils"],
    function (ArrayData, Utils) {

        function CustomData($element, options) {
            this.items = options.get('items')
            this.pageSize = options.get('pageSize')
            this.matcher = options.get('matcher')

            CustomData.__super__.constructor.call(this, $element, options);
        }


        let adapter = Utils.Extend(CustomData, ArrayData);

        adapter.prototype.query = function (params, callback) {

            let results = [];
            results = $.map(this.items, (item) => {
                return this.matcher(params, item)
            })

            if ($.trim(params.term) != '') {
                results = results.sort((a, b) => {
                    return a.text.replace(/\(.*\)/g, '').length - b.text.replace(/\(.*\)/g, '').length
                })
            }


            if (!("page" in params)) {
                params.page = 1;
            }
            let data = {};
            data.results = results.slice((params.page - 1) * this.pageSize, params.page * this.pageSize);
            data.pagination = {};
            data.pagination.more = params.page * this.pageSize < results.length;
            callback(data);
        };

        return adapter
    }
);