import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static values = {
        disabled: Boolean
    };

    static targets = ['active', 'links']

    connect() {

        if (this.hasActiveTarget) {
            this.switch_classes($(this.activeTarget))
        }

        if (this.disabledValue) {
            this.disabledLinks()
        }
    }

    active(event) {
        if (!this.hasActiveTarget) {

        }
        else if (this.activeTarget != event.currentTarget) {
            this.switch_classes($(this.activeTarget))
            $(this.activeTarget).removeAttr('data-links--pills-list-target')
        }
        this.switch_classes($(event.currentTarget))
        $(event.currentTarget).attr('data-links--pills-list-target', 'active')
    }

    switch_classes(dom) {
        dom.parent().toggleClass('btn-pills--transparent btn-pills--active')
        dom.toggleClass('text-black text-white')
    }

    disabledLinks() {
        $(this.linksTarget).find('a').each((i, link) => {
            $(link).click(function(event) {
                event.preventDefault();
                return false;
            } )
        });
    }
}