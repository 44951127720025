import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = ['group']

    connect() {
        // Clone field to have template to insert
        let field = $(this.groupTarget).find('.form_field')
        this.field_template = field.clone();

        // Is field a select2 input ?
        this.is_select2 = field.attr('data-controller') == 'select2';
        this.select_count = 1;
    }

    remove(event) {
        let n_fields = $(this.groupTarget).find('.form_field').length;

        if (n_fields == 1) {
            toastr.error('Vous ne pouvez pas supprimer un champ unique', 'Attention');
            return
        }

        $(event.currentTarget).closest('.form_field').remove()
    }

    add(event) {
        let last_field = $(this.groupTarget).find('.form_field:last')
        var dom_to_appened = this.field_template.clone();

        if (this.is_select2) {
            let select_dom = $(last_field).find('select');
            let select_num = parseInt(select_dom.attr('id').match(/_(\d+)/)[1]);

            dom_to_appened.find('select')
                          .attr('id', `select_${select_num + 1}`)

        }

        last_field.after(dom_to_appened)
    }
}