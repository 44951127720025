import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static values = {
        id: String,
        url: String
    }

    changeUrl(event) {
        $(`form#${this.idValue}`).attr('action', this.urlValue);
    }

    displayForm(event) {
        $(`form#${this.idValue}`).removeClass('d-none');
    }
}