import { Controller } from "@hotwired/stimulus"
import { customMatch, customMatchWithoutParentheses } from './select2/matchers'

export default class extends Controller {
    static targets = [ 'selectDom' , 'nestedField' ]
    static values = {
        showArrow: Boolean,
        selectionClass: { type: String, default: 'bg-white p-2 rounded-0 border-0' },
        matcher: { type: String, default: 'default'},
        placeholder: { type: String, default: '' },
        itemsUrl: { type: String, default: '' },
        selected: {type: String, default: ''}
    }

    clicked = false;

    connect() {
        let matcher = this.matcherValue === 'no_parentheses' ? customMatchWithoutParentheses : customMatch

        this.createSelect2($(this.selectDomTarget), matcher, this.selectionClassValue)

        //Fire change event on select2 change
        $(this.selectDomTarget).on('select2:select', function () {
            let event = new Event('change', { bubbles: true }) // fire a native event
            this.dispatchEvent(event);
        });

        // Remove arrow
        if (!this.showArrowValue) {
            var target = $(this.nestedFieldTarget)
            let observer = new MutationObserver(function(mutations) {
                if ($(target).find('b[role="presentation"]').length) {
                    $(target).find('b[role="presentation"]').hide();
                    observer.disconnect()
                }
            })

            observer.observe($(this.nestedFieldTarget).get(0), { childList: true, subtree: true })
        }
    }


    async createSelect2(elements, matcher) {
        let items = await this.getData();
        var classes = this.selectionClassValue;

        if (this.selectedValue){
            let _this = this
            let selected_item = items.find(element =>
                element.id == _this.selectedValue
            );

            $(this.selectDomTarget).append(new Option(selected_item.text, selected_item.id, true, true))
        }

        elements.select2({
            ajax: {},
            items: items,
            pageSize: 10,
            matcher: matcher,
            dataAdapter: $.fn.select2.amd.require('CustomDataAdapter'),
            selectionCssClass: classes
        })
    }

    async getData() {
        let results = await $.getJSON(this.itemsUrlValue)
        return results.items
    }
}