import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = ['showHideDom', 'image']

    showHide(event) {
        let dom_target = $(this.showHideDomTarget)
        dom_target.toggleClass('d-none d-block')

        if (dom_target.hasClass('d-none') ){
            event.currentTarget.innerHTML = 'Voir la lemmatisation'
        }
        else {
            event.currentTarget.innerHTML = 'Cacher la lemmatisation'
        }
    }

    previewImage(event) {
        let reader = new FileReader();

        let imageFile = event.currentTarget.files[0];
        let _this = this;

        $(this.imageTarget).removeClass('d-none');
        this.imageTarget.style.objectFit = 'cover';

        if ( $(this.imageTarget).next().hasClass('user-icon') ) {
            $(this.imageTarget).next().addClass('d-none')
        }

        reader.onload = function (e) {
            $(_this.imageTarget).attr({ src: e.target.result });
        }

        reader.readAsDataURL(imageFile);
    }
}
